<template>
<div class="liff-course-announcements-page">
  <div class="page__body" :class="{ 'fluid': userData.role < 2 }">
    <template v-if="!loading && announcementsData.length">
      <a-list
        class="comment-list"
        :data-source="announcementsData"
        item-layout="horizontal"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <a-comment
              class="comment-item"
              :author="`${item.info_doc.name} (${RoleUtil.toString(item.role).zh})`"
              :avatar="item.info_doc.avatar.uri"
              :content="item.content"
              :datetime="DateUtil.formatDatetime(item.created_datetime)"
            >
              <template #actions>
                <template v-if="item.likes.map(i => i.user_id).includes(userData.uid)">
                  <LikeFilled @click="deleteLikeAnnouncement(item.id)" :style="{ fontSize: '1.2rem', color: '#FF8900' }" />
                </template>
                <template v-else>
                  <LikeOutlined @click="likeAnnouncement(item.id)" :style="{ fontSize: '1.2rem' }" />
                </template>
                <span :style="{ fontSize: '1.2rem', paddingLeft: '2px', color: item.likes.length ? '#FF8900':''  }">
                  {{ item.likes.length }}
                </span>
              </template>
            </a-comment>
          </a-list-item>
        </template>
      </a-list>
    </template>

    <template v-if="!loading && !announcementsData.length">
      <a-empty
        class="py-6"
        :image="simpleImage"
        description="公告空空的"
        :style="{ fontSize: '1.3rem', color: '#656565' }"
      />
    </template>

    <!-- Loading -->
    <template v-if="loading">
      <div align="center" :style="{ padding: '40px 0' }">
        <a-spin tip="載入資料中..." :style="{ margin: '0 auto' }" />
      </div>
    </template>
    <!-- Loading -->
  </div>
  
  <div class="page__actions" v-if="userData.role > 1">
    <a-textarea
      class="mb-2"
      v-model:value="formState.content"
      :rows="3"
      :disabled="submitting"
      :style="{ fontSize: '1rem' }"
    />
    <a-button
      @click="insertOneAnnouncement"
      :loading="submitting"
      html-type="submit"
      type="primary"
      size="large"
      :style="{ width: '100%' }"
    >
      新增公告
    </a-button>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import liff from '@line/liff/dist/lib'
import { message, Empty } from 'ant-design-vue'
import { LikeFilled, LikeOutlined } from '@ant-design/icons-vue'
import api from '@/apis'
import RoleUtil from '@/utils/RoleUtil'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {
    LikeFilled,
    LikeOutlined
  },
  setup() {
    const route = useRoute();

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_MY_COURSES });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Loading */
    const loading = ref(false);
    const submitting = ref(false);

    /* Data */
    const userData = ref({});
    const announcementsData = ref([]);

    /* Announcement Form State -> Assistant & Coach */
    const initialState = {
      content: ''
    }
    const formState = ref({});
    formState.value = { ...initialState };

    /* Api */
    const getUserDataByLineId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      userData.value = response.data.result;
      console.log(userData.value)
    });

    const getAnnouncementsData = (async () => {
      announcementsData.value = [];

      const response = await api.v1.course.announcementList(route.params.course_id);
      announcementsData.value = response.data.result;

      console.log(announcementsData.value);
    });

    const insertOneAnnouncement = (async () => {
      submitting.value = true;
      
      try {
        await api.v1.course.insertOneAnnouncement(route.params.course_id, {
          author_id: userData.value.uid,
          content: formState.value.content
        });
        formState.value = { ...initialState };

        message.success('新增公告成功');
        getAnnouncementsData();
      } catch (error) {
        message.success('新增公告發生錯誤，請稍後再試');
      } finally {
        submitting.value = false;
      }
    });

    const likeAnnouncement = (async (announcement_id) => {
      try {
        await api.v1.user.likeAnnouncement(userData.value.uid, {
          announcement_id: announcement_id
        });

        announcementsData.value.forEach(item => {
          if (item.id === announcement_id) {
            item.likes.push({
              user_id: userData.value.uid
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    });

    const deleteLikeAnnouncement = (async (announcement_id) => {
      try {
        await api.v1.user.deleteLikeAnnouncement(userData.value.uid, {
          announcement_id: announcement_id
        });
        
        let announcementIndex = undefined;
        let likeIndex = undefined;
        for (let i=0; i<announcementsData.value.length; i++) {
          const item = announcementsData.value[i];
          if (item.id === announcement_id) announcementIndex = i;
          
          for (let likeIdx=0; likeIdx<item.likes.length; likeIdx++) {
            const likeItem = item.likes[likeIdx];
            if (likeItem.user_id === userData.value.uid) {
              likeIndex = likeIdx;
              break
            }
          }
        }
        announcementsData.value[announcementIndex].likes.splice(likeIndex, 1);
      } catch (error) {
        console.log(error);
      }
    });

    /* Mounted */
    onMounted(async () => {
      loading.value = true;

      await initLiff();
      await getUserDataByLineId();
      await getAnnouncementsData();

      loading.value = false;
    });

    return {
      /* Loaing */
      loading,

      /* Data */
      userData,
      announcementsData,

      /* Announcement Form State: Assistant & Coach */
      formState,

      /* Api */
      insertOneAnnouncement,
      likeAnnouncement,
      deleteLikeAnnouncement,

      /* Utils */
      RoleUtil,
      DateUtil,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  methods: {}
})
</script>

<style lang="scss">
.liff-course-announcements-page {
  position: relative;
  height: calc(100vh - 152px);

  .page__body {
    height: calc(100% - 140px);
    overflow: auto;
    margin-bottom: 12px;
    
    &.fluid {
      height: 100%;
      margin-bottom: 0;
    }

    .comment-list {
      .ant-list-item {
        padding-top: 0 !important;

        .ant-comment-inner {
          .ant-comment-content-author {
            margin-bottom: 12px;

            .ant-comment-content-author-name {
              font-size: 1.05rem;
              font-weight: 500;
            }
          }

          .ant-comment-content-detail {
            font-size: 1.2rem;
          }
        }
      }

      .comment-item {
        width: 100%;
        background: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        white-space: pre-wrap;
        padding: 8px 16px;
        margin-bottom: 4px;
      }
    }
  }

  .page__actions {
    height: 140px;
    overflow: auto;
    // height: 150px;
  }
}
</style>